import { inject, Injectable } from '@angular/core';
import { EmitMessage } from '@app/shared/decorators/message-emitter.decorator';
import { arrayToMap } from '@app/shared/services/utils-helper.service';
import { Action, createSelector, Selector, State, StateContext, Store } from '@ngxs/store';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs';
import { Assessment } from '../model/assessment.model';
import {
  CmsConnectionInfo,
  isSiteviewModel,
  Network,
  Poi,
  SiteMap,
  SiteTableItem,
  SiteViewModel,
} from '../model/site.model';
import { SiteService } from '../services/site.service';
import {
  CreateSite,
  CreateSiteSuccessful,
  FetchAssessments,
  FetchCMSConnectionInfos,
  FetchNetworks,
  FetchSiteDetail,
  FetchSites,
  GenerateSignedUrl,
  ProcessAssessments,
  PublishSites,
  SetPhoto,
  UpdateSite,
} from './site-management.actions';

export interface SiteManagementStateModel {
  sites: SiteMap;
  networks: { [id: string]: Network };
  assessments: { [id: string]: Assessment };
  assessmentsLoading: boolean;
  allAssessmentsLoaded: boolean;
  allSitesLoaded: boolean;
  allNetworksLoaded: boolean;
  cmsPlayers: { [id: string]: Poi };
  cmsConnectionInfos: { [id: string]: CmsConnectionInfo };
  sitesTimestamp: number;
  networksTimestamp: number;
}

@State<SiteManagementStateModel>({
  name: 'siteManagement',
  defaults: {
    sites: null,
    networks: null,
    assessments: null,
    assessmentsLoading: true,
    allAssessmentsLoaded: false,
    allSitesLoaded: false,
    allNetworksLoaded: false,
    cmsPlayers: null,
    cmsConnectionInfos: null,
    sitesTimestamp: null,
    networksTimestamp: null,
  },
})
@Injectable()
export class SiteManagementState {
  private _siteService = inject(SiteService);
  private _store = inject(Store);

  static selectSite(id: string) {
    return createSelector([SiteManagementState], (state: SiteManagementStateModel): SiteViewModel => {
      return state?.sites && isSiteviewModel(state.sites[id]) && (state.sites[id] as SiteViewModel);
    });
  }

  @Selector()
  static allSitesLoaded(state: SiteManagementStateModel): boolean {
    return state?.allSitesLoaded;
  }

  @Selector()
  static siteList(state: SiteManagementStateModel): (SiteTableItem | SiteViewModel)[] {
    return state?.sites && Object.values(state.sites);
  }

  @Selector()
  static siteMap(state: SiteManagementStateModel): SiteMap {
    return state?.sites && state.sites;
  }

  @Selector()
  static cmsPlayersList(state: SiteManagementStateModel): Poi[] {
    return state?.cmsPlayers && Object.values(state.cmsPlayers);
  }

  @Selector()
  static cmsConnectionInfoList(state: SiteManagementStateModel): CmsConnectionInfo[] {
    return state?.cmsConnectionInfos && Object.values(state.cmsConnectionInfos);
  }

  @Selector()
  static networkList(state: SiteManagementStateModel): Network[] {
    return state?.networks && Object.values(state.networks);
  }

  @Selector()
  static networks(state: SiteManagementStateModel): { [id: string]: Network } {
    return state?.networks;
  }

  @Selector()
  static assessments(state: SiteManagementStateModel): { [id: string]: Assessment } {
    return state?.assessments;
  }

  @Selector()
  static assessmentsList(state: SiteManagementStateModel): Assessment[] {
    return state?.assessments && Object.values(state.assessments);
  }

  @Selector([SiteManagementState.assessmentsList])
  static pendingAssessmentsList(assessmentsList): Assessment[] {
    return !!assessmentsList?.length && assessmentsList.filter((assessment) => !!assessment?.pending?.length);
  }

  @Selector()
  static assessmentsLoading(state: SiteManagementStateModel): boolean {
    return state?.assessmentsLoading;
  }

  @Selector()
  static allAssessmentsLoaded(state: SiteManagementStateModel): boolean {
    return state?.allAssessmentsLoaded;
  }

  @Action(FetchSites)
  fetchSites(ctx: StateContext<SiteManagementStateModel>) {
    const state = ctx.getState();
    return this._siteService.getSites().pipe(
          tap((sites: SiteTableItem[]) => {
            !!state?.sites
              ? sites.forEach((site: SiteTableItem) => {
                  state.sites[site.id] && delete site.address;
                  this.setSite(ctx, site, site.id);
                })
              : ctx.patchState({ sites: arrayToMap(sites) });
            ctx.patchState({
              allSitesLoaded: true,
            });
          }),
          catchError((err) => {
            throw err;
          }),
        );
  }

  // @Action(FetchCMSPlayers)
  // fetchCMSPlayers({ patchState, getState }) {
  //   const state = getState();
  //   return this._siteService
  //     .getCMSPlayers()
  //     .pipe(tap(cmsPlayers => patchState({ cmsPlayers: { ...state.cmsPlayers, ...cmsPlayers } })));
  // }

  @Action(FetchCMSConnectionInfos)
  fetchCMSConnectionInfos({ patchState, getState }) {
    const state = getState();
    return this._siteService.getCMSConnectionInfo().pipe(
      tap((cmsConnectionInfos) => {
        cmsConnectionInfos = arrayToMap(cmsConnectionInfos, 'id');
        patchState({ cmsConnectionInfos: { ...state.cmsConnectionInfos, ...cmsConnectionInfos } });
      }),
    );
  }

  @Action(FetchNetworks)
  fetchNetworks(ctx: StateContext<SiteManagementStateModel>) {
    const state = ctx.getState();

      return this._siteService.getNetworks().pipe(
        tap((networks: Network[]) => {
          ctx.patchState({
            networks: arrayToMap(networks),
            allNetworksLoaded: true,
          });
        }),
      );
  }

  @Action(FetchSiteDetail)
  fetchSiteDetail(ctx: StateContext<SiteManagementStateModel>, { id }: FetchSiteDetail) {
    const state = ctx.getState();
    if (!state?.sites || !isSiteviewModel(state?.sites[id])) {
      return this._siteService.getSite(id).pipe(
        map((site: any) => {
          site.latitude = parseFloat(site.latitude);
          site.longitude = parseFloat(site.longitude);
          return site;
        }),
        tap((site: SiteViewModel) => {
          this.setSite(ctx, site, site.id);
        }),
        catchError((err) => {
          throw err;
        }),
      );
    }
  }

  @EmitMessage({ afterAction: { entity: 'site.site', action: 'actionMessage.updatedSuccess' } })
  @Action(UpdateSite)
  updateSite(ctx: StateContext<SiteManagementStateModel>, { payload }: UpdateSite) {
    const { id } = payload;
    const state = ctx.getState();

    return this._siteService.updateSite(payload).pipe(
      tap((response) => {
        const state = ctx.getState();


        ctx.patchState({
          sites: {
            ...state.sites,
            [id]: {
              ...payload,
              networkName: (state.sites?.[id] as any)?.networkName,
              numOfPlayers: payload.pois.filter((poi) => !!poi.playerId).length,
              latitude: parseFloat(payload.latitude),
              longitude: parseFloat(payload.longitude),
              ...response,
            },
          },
        });
      }),
      catchError((err) => {
        ctx.setState(state);
        throw err;
      }),
    );
  }

  @EmitMessage({ afterAction: { entity: 'site.site', action: 'actionMessage.createdSuccess' } })
  @Action(CreateSite)
  createSite({ getState, setState, patchState, dispatch }, { payload }: CreateSite) {
    const state = getState();
    const contactName = payload.contact?.split(/\s+/);

    return this._siteService.createSite(payload).pipe(
      switchMap((response: SiteViewModel) => {
        const site = {
          ...response,
          numOfPlayers: response.pois.filter((poi) => !!poi.playerId).length,
          latitude: response.latitude,
          longitude: response.longitude,
          contact:
            (!!contactName.length && { ...response.contact, givenName: contactName[0], name: contactName[1] }) || null,
        };

        patchState({
          sites: { ...state.sites, [site.id]: { ...state.sites?.[site.id], ...site } },
        });

        return dispatch(new CreateSiteSuccessful(site));
      }),
      catchError((err) => {
        setState(state);
        throw err;
      }),
    );
  }

  @Action(CreateSiteSuccessful)
  createSiteSuccessful({}, { site }) {
    return of(site);
  }

  @EmitMessage({ afterAction: { entity: 'site.site', action: 'actionMessage.publishedSuccess' } })
  @Action(PublishSites)
  publishSites(ctx: StateContext<SiteManagementStateModel>, { sites }: PublishSites) {
    const state = ctx.getState();
    sites.forEach((siteId) => {
      this.setSite(
        ctx,
        {
          ...state.sites[siteId],
          published: true,
        },
        siteId,
      );
    });
    return this._siteService.publish(sites).pipe(
      catchError((err) => {
        ctx.setState(state);
        throw err;
      }),
    );
  }

  @EmitMessage({ afterAction: { entity: 'site.photo', action: 'actionMessage.updatedSuccess' } })
  @Action(SetPhoto)
  setPhoto(ctx: StateContext<SiteManagementStateModel>, { data }: SetPhoto) {
    const state = ctx.getState();

    const sites = structuredClone(state.sites);
    data?.sites?.forEach((siteId: string) => {
      sites[siteId] = { ...sites[siteId], imageUrl: data.url, imageUri: data.uri };
    });
    ctx.patchState({ sites });

    return this._siteService.setPhoto(data).pipe(
      catchError((err) => {
        ctx.setState(state);
        throw err;
      }),
    );
  }

  //Currently disabled

  // @Action(UnpublishSites)
  // unPublishSites(ctx: StateContext<SiteManagementStateModel>, { sites }: UnpublishSites) {
  //   const state = ctx.getState();
  //   sites.forEach(siteId => {
  //     this.setSite(ctx, {
  //       ...state.sites[siteId],
  //       published: false
  //     }, siteId);
  //   })
  //   return this._siteService.publish(sites).pipe(
  //     catchError(err => {
  //       ctx.setState(state);
  //       throw err;
  //     })
  //   );
  // }

  private setSite(ctx: StateContext<SiteManagementStateModel>, site: SiteViewModel | SiteTableItem, id: string) {
    const state = ctx.getState();
    ctx.patchState({
      sites: { ...state.sites, [id]: { ...state.sites?.[id], ...site } },
    });
  }

  @Action(FetchAssessments)
  fetchAssessments({ patchState, getState }, { completeHistory }) {
    // const assessmentsList = this._store.selectSnapshot(SiteManagementState.assessmentsList);

    !completeHistory && patchState({ assessmentsLoading: true });

    return this._siteService.getAssessments(completeHistory).pipe(
      tap((res: Assessment[]) => {
        const state = getState();
        let response = res?.map((r) => ({ ...r, id: `${r.assetId}${r.flightId}` }));

        if (state?.assessments) {
          const previousAssessments = state?.assessments && Object.values(state?.assessments);

          response = response.map((res) => {
            const duplicateItem = previousAssessments.find(
              (ass: Assessment) => ass.flightId === res.flightId && ass.assetId === res.assetId,
            );

            return duplicateItem ? { ...(duplicateItem as Assessment), ...res } : res;
          });
        }

        const assessments = arrayToMap(response);
        const allAssessmentsLoaded = completeHistory ? true : state.allAssessmentsLoaded;

        patchState({
          assessments: { ...assessments, ...state.assessments },
          assessmentsLoading: false,
          allAssessmentsLoaded,
        });
      }),
    );
  }

  @Action(GenerateSignedUrl)
  generateSignedUrl({ patchState, getState }, { id }) {
    const assetId = this._store.selectSnapshot(SiteManagementState.assessments)?.[id]?.assetId;
    this._siteService.generateSignedUrl(assetId).subscribe(({ url }) => {
      const state = getState();
      patchState({ assessments: { ...state.assessments, [id]: { ...state.assessments[id], assetUri: url } } });
    });
  }

  @EmitMessage({ afterAction: { entity: 'asset.assessment.entity', action: 'asset.assessment.processed' } })
  @Action(ProcessAssessments)
  processAssessments({ patchState, getState }, { payload }) {
    const state = getState();
    const newlyAssesed = [...payload.accept, ...payload.reject];

    const assessment = structuredClone(state.assessments[payload.id]);
    assessment.accepted = [...assessment.accepted.filter((id) => !newlyAssesed.includes(id)), ...payload.accept];
    assessment.rejected = [
      ...assessment.rejected.filter((rejection) => !newlyAssesed.includes(rejection.siteInventory)),
      ...payload.reject.map((siteInventory) => ({ siteInventory, message: payload.message })),
    ];
    assessment.pending = [...assessment.pending.filter((id) => !newlyAssesed.includes(id))];

    return this._siteService.processAssessment(payload).pipe(
      tap(() => {
        // infoDialog
        patchState({ assessments: { ...state.assessments, [assessment.id]: assessment } });
      }),
      // catchError(() => patchState({ assessments: { ...state.assessments } }))
    );
  }
}
