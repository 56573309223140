import { RefreshStore } from '@app/shared/model/shared.model';
import { AssessmentPayload } from '../model/assessment.model';
import { SiteViewModel } from '../model/site.model';

export class FetchSites {
  static readonly type = '[SiteManagement] FetchSites';
  constructor(public refreshStore: RefreshStore = {stateName: 'siteManagement', listAttribute: 'sites', loadingAttribute: 'allSitesLoaded'}) {}
}
export class FetchNetworks {
  static readonly type = '[SiteManagement] FetchNetworks';
  constructor(public refreshStore: RefreshStore = {stateName: 'siteManagement', listAttribute: 'networks', loadingAttribute: 'allNetworksLoaded'}) {}
}

export class FetchSiteDetail {
  static readonly type = '[SiteManagement] FetchSiteDetail';
  constructor(public id: string) {}
}

export class CreateSite {
  static readonly type = '[SiteManagement] CreateSite';
  constructor(public payload) {}
}

export class CreateSiteSuccessful {
  static readonly type = '[SiteManagement] CreateSiteSuccessful';
  constructor(public site: SiteViewModel) {}
}

export class UpdateSite {
  static readonly type = '[SiteManagement] UpdateSite';
  constructor(public payload) {}
}

export class PublishSites {
  static readonly type = '[SiteManagement] PublishSites';
  constructor(public sites: string[]) {}
}

export class UnpublishSites {
  static readonly type = '[SiteManagement] UnpublishSites';
  constructor(public sites: string[]) {}
}

export class DeleteSite {
  static readonly type = '[SiteManagement] DeleteSite';
  constructor(public sites: string[]) {}
}

export class SetPhoto {
  static readonly type = '[SiteManagement] SetPhoto';
  constructor(public data: any) {}
}
export class FetchReport {
  static readonly type = '[SiteManagement] FetchReport';
  constructor(public data) {}
}
export class FetchAssessments {
  static readonly type = '[SiteManagement] FetchAssessments';
  constructor(public completeHistory: boolean) {}
}
export class ProcessAssessments {
  static readonly type = '[SiteManagement] ProcessAssessments';
  constructor(public payload: AssessmentPayload) {}
}

export class GenerateSignedUrl {
  static readonly type = '[SiteManagement] GenerateSignedUrl';
  constructor(public id: string) {}
}

export class ToggleAssessmentOpenState {
  static readonly type = '[SiteManagement] ToggleAssessmentOpenState';
  constructor(public id: string) {}
}

// export class FetchCMSPlayers {
//   static readonly type = '[SiteManagement] FetchCMSPlayers';
//   constructor() {}
// }
export class FetchCMSConnectionInfos {
  static readonly type = '[SiteManagement] FetchCMSConnectionInfos';
  constructor() {}
}
